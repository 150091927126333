import React from 'react'
import Layout from '../components/layout'

import {
  Container,
  Heading,
  BlockHead,
  Spacer,
  LinkBase,
} from '../components/atoms'

import { Flex, Text, Box, Image } from 'rebass'

export const query = graphql`
  query PostPageQuery {
    posts: allSanityPost(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fixed(width: 600) {
                width
                height
                src
              }
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`

const headingStyle = {
  fontSize: [5],
  pb: 3,
}

const BlogPage = props => {
  const { data, errors } = props

  if (errors) throw errors

  const postEdges = (data && data.posts && data.posts.edges) || []

  return (
    <Layout>
      <Container>
        <Spacer>
          <Heading fontSize={5}>Blogs</Heading>
        </Spacer>
        {postEdges && (
          <Container>
            {postEdges.map(post => (
              <Flex flexWrap="wrap" className="">
                <LinkBase to={`/blog/${post.node.id}`}>
                  <Text fontSize={4} fontWeight={600}>{post.node.title}</Text>
                </LinkBase>
              </Flex>
            ))}
          </Container>
        )}
      </Container>
    </Layout>
  )
}
export default BlogPage
